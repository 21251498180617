import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { artDirecting } from '../utils/components'
import { ImageType, StyleType } from '../utils/prop-types'

const Image = ({ style, image, imageMobile, alt, ...props }) => {
  if (image && image.format && image.format === 'svg') {
    return imageMobile && imageMobile.url ? (
      <picture css={[tw`w-full h-full`, style]}>
        <source media="(max-width: 1023px)" srcSet={imageMobile.url} type="image/svg+xml" />
        <source srcSet={image.url} type="image/svg+xml" />
        <img css={[tw`w-full h-full`]} src={image.url} alt={alt || ''} />
      </picture>
    ) : (
      <img css={[tw`w-full h-full`]} src={image.url} alt={alt || ''} loading="lazy" />
    )
  }

  return (
    <GatsbyImage css={style} image={artDirecting(image, imageMobile)} alt={alt || ''} {...props} />
  )
}

Image.propTypes = {
  image: ImageType,
  imageMobile: ImageType,
  style: StyleType,
  alt: PropTypes.string,
  props: PropTypes.array,
}

export default Image
